@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply bg-gray-100;
	}
}

@layer utilities {
	.sidebar-collapse {
		& > .main {
			min-height: calc(100vh - calc(theme('spacing.14') + theme('spacing.16')));
		}
		& > .navbar,
		& > .main,
		& > .footer {
			@apply lg:ml-64;
		}
		& > .sidebar > .sidebar-event {
			@apply hidden;
		}
		& > .sidebar > .sidebar-menu {
			@apply -ml-64 lg:ml-0;
		}
	}
}

.rich-text-editor {
	min-height: 30rem;
	.ql-tooltip {
		left: 0 !important;
		right: 0 !important;
		top: 0 !important;
		padding: 5px 5px !important;
		input {
			height: 30px !important;
			margin-right: 5px !important;
		}
	}
}

.jodit-react-container {
	.jodit_sticky {
		& > .jodit-toolbar__box {
			top: 56px !important;
		}
	}
	.jodit-workplace {
		background-color: #fff;
		@apply prose prose-slate lg:prose-lg min-w-full;
	}
}

/* TinyMCE React */

.tox:not([dir='rtl']) {
	.tox-toolbar__group:not(:last-of-type) {
		border-right: 1px solid #eee !important;
	}
}

.tox {
	.tox-toolbar__overflow,
	.tox-toolbar__primary {
		background-repeat: repeat !important;
	}
}

.tox:not(.tox-tinymce-inline) {
	.tox-editor-header {
		border-bottom: 1px solid #eee !important;
		padding-bottom: 0 !important;
	}
	&.tox-tinymce--toolbar-sticky-on {
		.tox-editor-header {
			top: 56px !important;
		}
	}
}
